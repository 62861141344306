import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'components/modal';
// eslint-disable-next-line camelcase
import { post_api_secret_check } from 'components/api';

import TencentLogo from './img/tenc_pic.png';

import './style.less';
import { runGuide } from 'components/tc-guide';

class OaPopUps extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      url: '',
    };
  }

  componentDidMount() {
    post_api_secret_check().then((json) => {
      if (!json.data) {
        // 如果没有oa关联弹窗
        // 尝试展示一波引导弹窗
        runGuide();

        // 引导弹窗的注册可能会比oa检查接口慢，所以在全局标记一下
        window.__tencent_oa_secret_checked = true;
        return;
      }

      this.setState({ url: json.data });
    });
  }

  onClick(event) {
    const { state } = this;

    location.href = state.url;
  }

  onClickClose(event) {
    this.setState({ url: '' });
    runGuide();
    // 全局标记一下，尝试展示过guide
    window.__tencent_oa_secret_checked = true;
  }

  render() {
    const { state, props } = this;

    if (!state.url) {
      return null;
    }

    return (
      <Modal onClose={this.onClickClose.bind(this)}>
        <div className="oa_pop_ups">
          <div className="oa_avatar">
            <div className="oa_avatar_relation">
              <span className="user_pic">
                <img src={props.avatar} alt="avatar" ></img>
              </span>
              <span className="icon_jt"></span>
              <span className="user_pic">
                <img src={TencentLogo} alt="logo" ></img>
              </span>
            </div>
          </div>
          <div className="oa_content">
            <h5 className="title">腾讯内部员工一键关联</h5>
            <p className="desc">
              检测到你是腾讯公司内部IP，为了方便我们更好的为您服务，请一键关联你的OA账号。关联后不会对你的使用带来任何影响。
            </p>
            <button className="relation_btn" onClick={this.onClick.bind(this)}>一键关联</button>
          </div>
          <div className="legend_font">有任何问题请联系在企业微信搜索：兔小巢助手</div>
        </div>
      </Modal>
    );
  }
}

OaPopUps.propTypes = {
  avatar: PropTypes.string,
};

OaPopUps.defaultProps = {
  avatar: '',
};

export { OaPopUps };
